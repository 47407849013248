[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-BankTransfer .path1:before {
  content: "\e900";
  color: rgb(255, 255, 255);
}
.icon-BankTransfer .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(34, 34, 34);
}
.icon-BankTransfer .path3:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(34, 34, 34);
}
.icon-BankTransfer .path4:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(34, 34, 34);
}
.icon-BankTransfer .path5:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(34, 34, 34);
}
.icon-paypalstacked .path1:before {
  content: "\e905";
  color: rgb(255, 255, 255);
}
.icon-paypalstacked .path2:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(35, 142, 194);
}
.icon-paypalstacked .path3:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(37, 54, 103);
}
.icon-paypalstacked .path4:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(37, 54, 103);
}
.icon-paypalstacked .path5:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(35, 142, 194);
}
.icon-paypalstacked .path6:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(37, 54, 103);
}
.icon-paypalstacked .path7:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(35, 142, 194);
}
.icon-Sadad .path1:before {
  content: "\e90c";
  color: rgb(255, 255, 255);
}
.icon-Sadad .path2:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(146, 148, 151);
}
.icon-Sadad .path3:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(237, 118, 36);
}
.icon-Sadad .path4:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(237, 118, 36);
}
.icon-g_pay .path1:before {
  content: "\e910";
  color: rgb(255, 255, 255);
}
.icon-g_pay .path2:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(60, 64, 67);
}
.icon-g_pay .path3:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(60, 64, 67);
}
.icon-g_pay .path4:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(60, 64, 67);
}
.icon-g_pay .path5:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(66, 133, 244);
}
.icon-g_pay .path6:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(52, 168, 83);
}
.icon-g_pay .path7:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(251, 188, 4);
}
.icon-g_pay .path8:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(234, 67, 53);
}
.icon-apple_pay .path1:before {
  content: "\e918";
  color: rgb(255, 255, 255);
}
.icon-apple_pay .path2:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mc .path1:before {
  content: "\e91a";
  color: rgb(255, 255, 255);
}
.icon-mc .path2:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mc .path3:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(255, 95, 0);
}
.icon-mc .path4:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(235, 0, 27);
}
.icon-mc .path5:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(247, 158, 27);
}
.icon-mc .path6:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(247, 158, 27);
}
.icon-Visadark .path1:before {
  content: "\e920";
  color: rgb(255, 255, 255);
}
.icon-Visadark .path2:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(38, 51, 122);
}
.icon-Visadark .path3:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(38, 51, 122);
}
.icon-Visadark .path4:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(38, 51, 122);
}
.icon-Visadark .path5:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(38, 51, 122);
}
.icon-Visadark .path6:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(236, 152, 45);
}
.icon-Mada .path1:before {
  content: "\e926";
  color: rgb(255, 255, 255);
}
.icon-Mada .path2:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(132, 183, 64);
}
.icon-Mada .path3:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(37, 155, 214);
}
.icon-Mada .path4:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(39, 41, 45);
}
.icon-Mada .path5:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(39, 41, 45);
}
.icon-Mada .path6:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(39, 41, 45);
}
.icon-Mada .path7:before {
  content: "\e92c";
  margin-left: -1em;
  color: rgb(39, 41, 45);
}
.icon-Mada .path8:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(39, 41, 45);
}
.icon-Mada .path9:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(39, 41, 45);
}
.icon-greenArmor:before {
  content: "\e92f";
  color: #00b362;
}
.icon-ftrLogo:before {
  content: "\e930";
  color: #bbc8d3;
}
.icon-wpImg .path1:before {
  content: "\e931";
  color: rgb(255, 255, 255);
}
.icon-wpImg .path2:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-wpImg .path3:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-wpImg .path4:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-telephoneRed .path1:before {
  content: "\e935";
  color: rgb(254, 87, 28);
}
.icon-telephoneRed .path2:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(250, 250, 250);
}
.icon-instagram:before {
  content: "\e937";
  color: #bbc8d3;
}
.icon-down2:before {
  content: "\e938";
  color: #a7b2c0;
}
.icon-up:before {
  content: "\e939";
  color: #a7b2c0;
}
.icon-trade_sell_en .path1:before {
  content: "\e93a";
  color: rgb(21, 79, 156);
}
.icon-trade_sell_en .path2:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(21, 79, 156);
}
.icon-trade_sell_en .path3:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(21, 79, 156);
}
.icon-trade_sell_en .path4:before {
  content: "\e93d";
  margin-left: -1em;
  color: rgb(21, 79, 156);
}
.icon-trade_sell_en .path5:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(21, 79, 156);
}
.icon-trade_sell_en .path6:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(21, 79, 156);
}
.icon-trade_sell_en .path7:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(21, 79, 156);
}
.icon-trade_sell_en .path8:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(21, 79, 156);
}
.icon-trade_sell_en .path9:before {
  content: "\e942";
  margin-left: -1em;
  color: rgb(21, 79, 156);
}
.icon-trade_sell_en .path10:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(244, 215, 96);
}
.icon-trade_sell_en .path11:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(236, 98, 52);
}
.icon-saudi_elm .path1:before {
  content: "\e945";
  color: rgb(127, 132, 135);
}
.icon-saudi_elm .path2:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(84, 80, 78);
}
.icon-saudi_elm .path3:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(234, 232, 233);
}
.icon-saudi_elm .path4:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-saudi_elm .path5:before {
  content: "\e949";
  margin-left: -1em;
  color: rgb(127, 132, 135);
}
.icon-close_gry:before {
  content: "\e94a";
  color: #484848;
}
.icon-Online1:before {
  content: "\e94b";
  color: #6c7a8d;
}
.icon-UsedCarsOffers:before {
  content: "\e94c";
  color: #6c7a8d;
}
.icon-NewCarsOffers:before {
  content: "\e94d";
  color: #6c7a8d;
}
.icon-location_on-24px1:before {
  content: "\e94e";
  color: #6c7a8d;
}
.icon-business_center-24px-11:before {
  content: "\e94f";
  color: #6c7a8d;
}
.icon-group_cars:before {
  content: "\e950";
  color: #656565;
}
.icon-Finance1:before {
  content: "\e951";
  color: #6c7a8d;
}
.icon-Dealers1:before {
  content: "\e952";
  color: #6c7a8d;
}
.icon-Used-Cars1:before {
  content: "\e953";
  color: #6c7a8d;
}
.icon-New-Cars1:before {
  content: "\e954";
  color: #6c7a8d;
}
.icon-Car-Finance1:before {
  content: "\e955";
  color: #6c7a8d;
}
.icon-Dealers1-1:before {
  content: "\e956";
  color: #6c7a8d;
}
.icon-Wakalat1:before {
  content: "\e957";
  color: #6c7a8d;
}
.icon-aff:before {
  content: "\e958";
  color: #6c7a8d;
}
.icon-Blog1:before {
  content: "\e959";
  color: #6c7a8d;
}
.icon-Jobs1:before {
  content: "\e95a";
  color: #6c7a8d;
}
.icon-Call-Us1:before {
  content: "\e95b";
  color: #6c7a8d;
}
.icon-Who-We-Are1:before {
  content: "\e95c";
  color: #6c7a8d;
}
.icon-Facebook1:before {
  content: "\e95d";
  color: #6c7a8d;
}
.icon-Twitter1:before {
  content: "\e95e";
  color: #6c7a8d;
}
.icon-Instagram1:before {
  content: "\e95f";
  color: #6c7a8d;
}
.icon-Snapchat1:before {
  content: "\e960";
  color: #6c7a8d;
}
.icon-Youtube1:before {
  content: "\e961";
  color: #6c7a8d;
}

.icon-wpImg > .path2::before {
  color: rgb(15 189 81);
}
.icon-wpImg > .path3::before {
  color: rgb(255 255 255);
}
