/** Includes General Top Level Css Rules without Classes*/
body:not(.isFirstInteraction) .interActiveGray {
  color: black !important;
}

body:not(.isFirstInteraction) .interActiveBlue {
  color: #0a3e6e !important;
}

body:not(.isFirstInteraction) .interActiveGreen {
  color: #007f43 !important;
}

body:not(.isFirstInteraction) .interActiveBackOrange {
  background-color: #d32f2f !important;
}

body:not(.isFirstInteraction) .interActiveBackBlue {
  background-color: #0a3e6e !important;
}

body:not(.isFirstInteraction) .interActiveBackPurple {
  background-color: #0a2e4f !important;
}

iframe:not([id*="fc_widget"]):not([src]) {
  pointer-events: none !important;
  z-index: -1 !important;
  display: none !important;
}

/* iframe[id*="fc_widget"] {
  min-height: calc(100vh - 53px) !important;
  height: calc(100vh - 53px) !important;
} */
