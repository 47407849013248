.margin-auto {
  margin: 0 auto;
}

/* Padding utilities */
.p-1 {
  padding: var(--spacing-unit-1);
}
.pt-1 {
  padding-top: var(--spacing-unit-1);
}
.pb-1 {
  padding-bottom: var(--spacing-unit-1);
}
.ps-0 {
  padding-inline-start: 0 !important;
} /* Adapts to left (LTR) or right (RTL) */
.ps-1 {
  padding-inline-start: var(--spacing-unit-1);
} /* Adapts to left (LTR) or right (RTL) */
.pe-1 {
  padding-inline-end: var(--spacing-unit-1);
} /* Adapts to right (LTR) or left (RTL) */
.py-1 {
  padding-top: var(--spacing-unit-1);
  padding-bottom: var(--spacing-unit-1);
}

/* Margin utilities */
.m-1 {
  margin: var(--spacing-unit-1);
}
.mt-1 {
  margin-top: var(--spacing-unit-1) !important;
}
.mt-2 {
  margin-top: var(--spacing-unit-2) !important;
}
.mb-1 {
  margin-bottom: var(--spacing-unit-1) !important;
}
.mb-2 {
  margin-bottom: var(--spacing-unit-2) !important;
}
.ms-0 {
  margin-inline-start: 0 !important;
} /* Adapts to left (LTR) or right (RTL) */
.ms-1 {
  margin-inline-start: var(--spacing-unit-1) !important;
} /* Adapts to left (LTR) or right (RTL) */
.me-1 {
  margin-inline-end: var(--spacing-unit-1) !important;
} /* Adapts to right (LTR) or left (RTL) */
.my-1 {
  margin-top: var(--spacing-unit-1) !important;
  margin-bottom: var(--spacing-unit-1) !important;
}
/* added by sohaib */
.mt-32 {
  margin-top: var(--spacing-unit-32);
}
.mt-40 {
  margin-top: var(--spacing-unit-40);
}
/* added by sohaib */

/* width */
.w-60 {
  width: 60%;
}

/*Line height*/
.line-height-24 {
  line-height: 24px;
}

/* font-size */
.font-size-18 {
  font-size: 18px;
}
.font-bold {
  font-weight: 700;
}
.font-normal {
  font-weight: 400;
}

/* section*/
.max-width {
  max-width: 1150px;
  width: 90%;
}

/** utils */
.hide{
    display: none;
}

/**colors */
.bg-white{
    background-color: #fff;
}

@media (min-width: 1025px) {
  .m-show {
    display: none !important;
  }
}

@media (max-width: 1024px) {
  .s-mt-32 {
    margin-top: var(--spacing-unit-32);
  }
  .s-mt-40 {
    margin-top: var(--spacing-unit-40);
  }
  .m-hide {
    display: none !important;
  }
}
.cursor-pointer {
  cursor: pointer;
}
