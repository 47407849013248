/** Includes General Common Classes*/
*, ::before, ::after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
    --tw-contain-size:  ;
    --tw-contain-layout:  ;
    --tw-contain-paint:  ;
    --tw-contain-style:  ;
}
::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
    --tw-contain-size:  ;
    --tw-contain-layout:  ;
    --tw-contain-paint:  ;
    --tw-contain-style:  ;
}
.container {
    width: 100%;
}
@media (min-width: 640px) {
    .container {
        max-width: 640px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
}
@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }
}
@media (min-width: 1280px) {
    .container {
        max-width: 1280px;
    }
}
@media (min-width: 1536px) {
    .container {
        max-width: 1536px;
    }
}
.iconify {
    display: inline-block;
    width: 1em;
    height: 1em;
    background-color: currentColor;
    -webkit-mask-image: var(--svg);
    mask-image: var(--svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
}
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}
.pointer-events-none {
    pointer-events: none;
}
.visible {
    visibility: visible;
}
.static {
    position: static;
}
.fixed {
    position: fixed;
}
.absolute {
    position: absolute;
}
.relative {
    position: relative;
}
.sticky {
    position: sticky;
}
.inset-0 {
    inset: 0px;
}
.-top-\[6px\] {
    top: -6px;
}
.bottom-0 {
    bottom: 0px;
}
.bottom-\[15px\] {
    bottom: 15px;
}
.bottom-\[32px\] {
    bottom: 32px;
}
.bottom-\[88px\] {
    bottom: 88px;
}
.bottom-\[96px\] {
    bottom: 96px;
}
.left-0 {
    left: 0px;
}
.left-4 {
    left: 1rem;
}
.left-\[-2px\] {
    left: -2px;
}
.left-\[13px\] {
    left: 13px;
}
.left-\[50\%\] {
    left: 50%;
}
.right-0 {
    right: 0px;
}
.right-\[3px\] {
    right: 3px;
}
.right-\[5\%\] {
    right: 5%;
}
.top-0 {
    top: 0px;
}
.top-4 {
    top: 1rem;
}
.top-\[-12px\] {
    top: -12px;
}
.top-\[-8px\] {
    top: -8px;
}
.top-\[102\%\] {
    top: 102%;
}
.top-\[113px\] {
    top: 113px;
}
.top-\[12px\] {
    top: 12px;
}
.top-\[50\%\] {
    top: 50%;
}
.top-\[60px\] {
    top: 60px;
}
.top-\[7px\] {
    top: 7px;
}
.top-\[calc\(50\%-34px\/2\)\] {
    top: calc(50% - 34px / 2);
}
.top-\[calc\(50\%-8\.5px\)\] {
    top: calc(50% - 8.5px);
}
.z-10 {
    z-index: 10;
}
.z-30 {
    z-index: 30;
}
.z-40 {
    z-index: 40;
}
.z-50 {
    z-index: 50;
}
.z-\[1\] {
    z-index: 1;
}
.z-\[2\] {
    z-index: 2;
}
.z-\[3\] {
    z-index: 3;
}
.z-\[99999\] {
    z-index: 99999;
}
.z-\[999\] {
    z-index: 999;
}
.z-\[99\] {
    z-index: 99;
}
.z-\[9\] {
    z-index: 9;
}
.\!m-0 {
    margin: 0px !important;
}
.m-0 {
    margin: 0px;
}
.m-auto {
    margin: auto;
}
.mx-\[-12px\] {
    margin-left: -12px;
    margin-right: -12px;
}
.mx-\[2px\] {
    margin-left: 2px;
    margin-right: 2px;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
}
.my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.my-auto {
    margin-top: auto;
    margin-bottom: auto;
}
.\!mt-\[24px\] {
    margin-top: 24px !important;
}
.-mb-\[15px\] {
    margin-bottom: -15px;
}
.mb-1 {
    margin-bottom: 0.25rem;
}
.mb-2 {
    margin-bottom: 0.5rem;
}
.mb-4 {
    margin-bottom: 1rem;
}
.mb-6 {
    margin-bottom: 1.5rem;
}
.mb-7 {
    margin-bottom: 1.75rem;
}
.mb-8 {
    margin-bottom: 2rem;
}
.mb-\[-24px\] {
    margin-bottom: -24px;
}
.ms-0 {
    margin-inline-start: 0px;
}
.mt-0 {
    margin-top: 0px;
}
.mt-1 {
    margin-top: 0.25rem;
}
.mt-10 {
    margin-top: 2.5rem;
}
.mt-2 {
    margin-top: 0.5rem;
}
.mt-20 {
    margin-top: 5rem;
}
.mt-3 {
    margin-top: 0.75rem;
}
.mt-4 {
    margin-top: 1rem;
}
.mt-5 {
    margin-top: 1.25rem;
}
.mt-6 {
    margin-top: 1.5rem;
}
.mt-7 {
    margin-top: 1.75rem;
}
.mt-8 {
    margin-top: 2rem;
}
.mt-\[-65px\] {
    margin-top: -65px;
}
.mt-\[32px\] {
    margin-top: 32px;
}
.mt-\[40px\] {
    margin-top: 40px;
}
.mt-\[56px\] {
    margin-top: 56px;
}
.mt-\[8px\] {
    margin-top: 8px;
}
.mt-auto {
    margin-top: auto;
}
.box-border {
    box-sizing: border-box;
}
.line-clamp-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}
.line-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.block {
    display: block;
}
.inline-block {
    display: inline-block;
}
.inline {
    display: inline;
}
.flex {
    display: flex;
}
.inline-flex {
    display: inline-flex;
}
.grid {
    display: grid;
}
.hidden {
    display: none;
}
.aspect-\[4\/3\] {
    aspect-ratio: 4/3;
}
.aspect-square {
    aspect-ratio: 1 / 1;
}
.h-0\.5 {
    height: 0.125rem;
}
.h-11 {
    height: 2.75rem;
}
.h-2 {
    height: 0.5rem;
}
.h-20 {
    height: 5rem;
}
.h-3 {
    height: 0.75rem;
}
.h-4 {
    height: 1rem;
}
.h-4\.5 {
    height: 18px;
}
.h-5 {
    height: 1.25rem;
}
.h-6 {
    height: 1.5rem;
}
.h-7 {
    height: 1.75rem;
}
.h-8 {
    height: 2rem;
}
.h-9 {
    height: 2.25rem;
}
.h-\[130px\] {
    height: 130px;
}
.h-\[140px\] {
    height: 140px;
}
.h-\[156px\] {
    height: 156px;
}
.h-\[17px\] {
    height: 17px;
}
.h-\[18px\] {
    height: 18px;
}
.h-\[1px\] {
    height: 1px;
}
.h-\[24px\] {
    height: 24px;
}
.h-\[28px\] {
    height: 28px;
}
.h-\[328px\] {
    height: 328px;
}
.h-\[32px\] {
    height: 32px;
}
.h-\[40px\] {
    height: 40px;
}
.h-\[48px\] {
    height: 48px;
}
.h-\[56px\] {
    height: 56px;
}
.h-\[64px\] {
    height: 64px;
}
.h-\[6px\] {
    height: 6px;
}
.h-\[96px\] {
    height: 96px;
}
.h-full {
    height: 100%;
}
.h-px {
    height: 1px;
}
.max-h-\[476px\] {
    max-height: 476px;
}
.min-h-6 {
    min-height: 1.5rem;
}
.min-h-8 {
    min-height: 2rem;
}
.min-h-\[20px\] {
    min-height: 20px;
}
.min-h-\[48px\] {
    min-height: 48px;
}
.min-h-\[70px\] {
    min-height: 70px;
}
.min-h-\[calc\(100vh-61px\)\] {
    min-height: calc(100vh - 61px);
}
.min-h-\[calc\(100vh-70px\)\] {
    min-height: calc(100vh - 70px);
}
.min-h-full {
    min-height: 100%;
}
.\!w-full {
    width: 100% !important;
}
.w-1\/2 {
    width: 50%;
}
.w-11 {
    width: 2.75rem;
}
.w-24 {
    width: 6rem;
}
.w-3 {
    width: 0.75rem;
}
.w-4 {
    width: 1rem;
}
.w-4\.5 {
    width: 18px;
}
.w-5 {
    width: 1.25rem;
}
.w-56 {
    width: 14rem;
}
.w-6 {
    width: 1.5rem;
}
.w-7 {
    width: 1.75rem;
}
.w-8 {
    width: 2rem;
}
.w-9 {
    width: 2.25rem;
}
.w-\[156px\] {
    width: 156px;
}
.w-\[17px\] {
    width: 17px;
}
.w-\[18px\] {
    width: 18px;
}
.w-\[1px\] {
    width: 1px;
}
.w-\[250px\] {
    width: 250px;
}
.w-\[280px\] {
    width: 280px;
}
.w-\[44px\] {
    width: 44px;
}
.w-\[50\%\] {
    width: 50%;
}
.w-\[64px\] {
    width: 64px;
}
.w-\[74px\] {
    width: 74px;
}
.w-\[90\%\] {
    width: 90%;
}
.w-\[calc\(100\%-32px\)\] {
    width: calc(100% - 32px);
}
.w-\[calc\(100\%\/3\)\] {
    width: calc(100% / 3);
}
.w-\[calc\(50\%-5px\)\] {
    width: calc(50% - 5px);
}
.w-\[calc\(88px\)\] {
    width: calc(88px);
}
.w-fit {
    width: -moz-fit-content;
    width: fit-content;
}
.w-full {
    width: 100%;
}
.w-px {
    width: 1px;
}
.min-w-11 {
    min-width: 2.75rem;
}
.min-w-14 {
    min-width: 3.5rem;
}
.min-w-24 {
    min-width: 6rem;
}
.min-w-6 {
    min-width: 1.5rem;
}
.min-w-8 {
    min-width: 2rem;
}
.min-w-9 {
    min-width: 2.25rem;
}
.min-w-\[112px\] {
    min-width: 112px;
}
.min-w-\[115px\] {
    min-width: 115px;
}
.min-w-\[122px\] {
    min-width: 122px;
}
.min-w-\[166px\] {
    min-width: 166px;
}
.min-w-\[1px\] {
    min-width: 1px;
}
.min-w-\[298px\] {
    min-width: 298px;
}
.min-w-\[300px\] {
    min-width: 300px;
}
.min-w-\[50px\] {
    min-width: 50px;
}
.\!max-w-\[1150px\] {
    max-width: 1150px !important;
}
.\!max-w-full {
    max-width: 100% !important;
}
.max-w-\[1100px\] {
    max-width: 1100px;
}
.max-w-\[145px\] {
    max-width: 145px;
}
.max-w-\[150px\] {
    max-width: 150px;
}
.max-w-\[298px\] {
    max-width: 298px;
}
.max-w-\[90\%\] {
    max-width: 90%;
}
.max-w-\[958px\] {
    max-width: 958px;
}
.max-w-full {
    max-width: 100%;
}
.flex-shrink-0 {
    flex-shrink: 0;
}
.shrink-0 {
    flex-shrink: 0;
}
.grow {
    flex-grow: 1;
}
.-translate-x-\[calc\(100\%\+12px\)\] {
    --tw-translate-x: calc(calc(100% + 12px) * -1);
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-\[-21px\] {
    --tw-translate-x: -21px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-\[-50\%\] {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-\[calc\(100\%\+12px\)\] {
    --tw-translate-x: calc(100% + 12px);
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-\[-100\%\] {
    --tw-translate-y: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-\[-50\%\] {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-\[50\%\] {
    --tw-translate-y: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-rotate-90 {
    --tw-rotate: -90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-180 {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-90 {
    --tw-rotate: 90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.cursor-auto {
    cursor: auto;
}
.cursor-default {
    cursor: default;
}
.cursor-not-allowed {
    cursor: not-allowed;
}
.cursor-pointer {
    cursor: pointer;
}
.resize {
    resize: both;
}
.snap-x {
    scroll-snap-type: x var(--tw-scroll-snap-strictness);
}
.snap-mandatory {
    --tw-scroll-snap-strictness: mandatory;
}
.snap-center {
    scroll-snap-align: center;
}
.scroll-mt-\[170px\] {
    scroll-margin-top: 170px;
}
.appearance-none {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}
.flex-row {
    flex-direction: row;
}
.flex-col {
    flex-direction: column;
}
.flex-wrap {
    flex-wrap: wrap;
}
.flex-nowrap {
    flex-wrap: nowrap;
}
.content-center {
    align-content: center;
}
.items-start {
    align-items: flex-start;
}
.items-end {
    align-items: flex-end;
}
.items-center {
    align-items: center;
}
.items-baseline {
    align-items: baseline;
}
.justify-start {
    justify-content: flex-start;
}
.justify-end {
    justify-content: flex-end;
}
.justify-center {
    justify-content: center;
}
.justify-between {
    justify-content: space-between;
}
.justify-evenly {
    justify-content: space-evenly;
}
.gap-0\.5 {
    gap: 0.125rem;
}
.gap-1 {
    gap: 0.25rem;
}
.gap-2 {
    gap: 0.5rem;
}
.gap-2\.5 {
    gap: 0.625rem;
}
.gap-3 {
    gap: 0.75rem;
}
.gap-4 {
    gap: 1rem;
}
.gap-5 {
    gap: 1.25rem;
}
.gap-6 {
    gap: 1.5rem;
}
.gap-8 {
    gap: 2rem;
}
.gap-\[12px\] {
    gap: 12px;
}
.gap-\[16px\] {
    gap: 16px;
}
.gap-\[2px\] {
    gap: 2px;
}
.gap-\[32px\] {
    gap: 32px;
}
.gap-\[35px\] {
    gap: 35px;
}
.gap-\[4px\] {
    gap: 4px;
}
.gap-\[7px\] {
    gap: 7px;
}
.gap-\[9px\] {
    gap: 9px;
}
.space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}
.self-stretch {
    align-self: stretch;
}
.self-baseline {
    align-self: baseline;
}
.overflow-hidden {
    overflow: hidden;
}
.overflow-scroll {
    overflow: scroll;
}
.overflow-x-auto {
    overflow-x: auto;
}
.overflow-y-auto {
    overflow-y: auto;
}
.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.text-ellipsis {
    text-overflow: ellipsis;
}
.whitespace-normal {
    white-space: normal;
}
.whitespace-nowrap {
    white-space: nowrap;
}
.whitespace-pre {
    white-space: pre;
}
.text-balance {
    text-wrap: balance;
}
.break-words {
    overflow-wrap: break-word;
}
.rounded {
    border-radius: 0.25rem;
}
.rounded-\[1\.6px\] {
    border-radius: 1.6px;
}
.rounded-\[12px\] {
    border-radius: 12px;
}
.rounded-\[16px\] {
    border-radius: 16px;
}
.rounded-\[2px\] {
    border-radius: 2px;
}
.rounded-\[32px\] {
    border-radius: 32px;
}
.rounded-\[3px\] {
    border-radius: 3px;
}
.rounded-\[4px\] {
    border-radius: 4px;
}
.rounded-\[5px\] {
    border-radius: 5px;
}
.rounded-\[6px\] {
    border-radius: 6px;
}
.rounded-\[8px\] {
    border-radius: 8px;
}
.rounded-\[9px\] {
    border-radius: 9px;
}
.rounded-full {
    border-radius: 9999px;
}
.rounded-inherit {
    border-radius: inherit;
}
.rounded-lg {
    border-radius: 0.5rem;
}
.rounded-md {
    border-radius: 0.375rem;
}
.rounded-sm {
    border-radius: 0.125rem;
}
.rounded-b-\[18px\] {
    border-bottom-right-radius: 18px;
    border-bottom-left-radius: 18px;
}
.rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}
.rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
}
.rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
}
.rounded-tr-lg {
    border-top-right-radius: 0.5rem;
}
.border {
    border-width: 1px;
}
.border-0 {
    border-width: 0px;
}
.border-2 {
    border-width: 2px;
}
.border-\[0\.4px\] {
    border-width: 0.4px;
}
.border-b {
    border-bottom-width: 1px;
}
.border-r {
    border-right-width: 1px;
}
.border-t {
    border-top-width: 1px;
}
.border-solid {
    border-style: solid;
}
.border-dashed {
    border-style: dashed;
}
.border-none {
    border-style: none;
}
.border-\[\#3C56D4\] {
    --tw-border-opacity: 1;
    border-color: rgb(60 86 212 / var(--tw-border-opacity, 1));
}
.border-\[\#7F95FF\] {
    --tw-border-opacity: 1;
    border-color: rgb(127 149 255 / var(--tw-border-opacity, 1));
}
.border-\[\#E1E8F2\] {
    --tw-border-opacity: 1;
    border-color: rgb(225 232 242 / var(--tw-border-opacity, 1));
}
.border-\[\#E9E9E9\] {
    --tw-border-opacity: 1;
    border-color: rgb(233 233 233 / var(--tw-border-opacity, 1));
}
.border-\[\#FE561C\] {
    --tw-border-opacity: 1;
    border-color: rgb(254 86 28 / var(--tw-border-opacity, 1));
}
.border-\[\#e1e8f2\] {
    --tw-border-opacity: 1;
    border-color: rgb(225 232 242 / var(--tw-border-opacity, 1));
}
.border-\[\#f5f7fa\] {
    --tw-border-opacity: 1;
    border-color: rgb(245 247 250 / var(--tw-border-opacity, 1));
}
.border-current {
    border-color: currentColor;
}
.border-danger {
    --tw-border-opacity: 1;
    border-color: rgb(241 16 16 / var(--tw-border-opacity, 1));
}
.border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}
.border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
}
.border-gray-azureish {
    --tw-border-opacity: 1;
    border-color: rgb(225 232 242 / var(--tw-border-opacity, 1));
}
.border-gray-gainsboro {
    --tw-border-opacity: 1;
    border-color: rgb(214 223 233 / var(--tw-border-opacity, 1));
}
.border-gray-text {
    --tw-border-opacity: 1;
    border-color: rgb(72 72 72 / var(--tw-border-opacity, 1));
}
.border-neutral-10 {
    --tw-border-opacity: 1;
    border-color: rgb(214 223 233 / var(--tw-border-opacity, 1));
}
.border-neutral-60 {
    --tw-border-opacity: 1;
    border-color: rgb(224 224 224 / var(--tw-border-opacity, 1));
}
.border-neutral-70 {
    --tw-border-opacity: 1;
    border-color: rgb(192 192 192 / var(--tw-border-opacity, 1));
}
.border-primary {
    --tw-border-opacity: 1;
    border-color: rgb(60 86 212 / var(--tw-border-opacity, 1));
}
.border-primary-50 {
    --tw-border-opacity: 1;
    border-color: rgb(212 222 250 / var(--tw-border-opacity, 1));
}
.border-success {
    --tw-border-opacity: 1;
    border-color: rgb(0 179 98 / var(--tw-border-opacity, 1));
}
.border-transparent {
    border-color: transparent;
}
.border-warning {
    --tw-border-opacity: 1;
    border-color: rgb(255 185 0 / var(--tw-border-opacity, 1));
}
.border-white {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}
.border-b-gray-gainsboro {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(214 223 233 / var(--tw-border-opacity, 1));
}
.border-t-\[\#E2E2E2\] {
    --tw-border-opacity: 1;
    border-top-color: rgb(226 226 226 / var(--tw-border-opacity, 1));
}
.bg-\[\#00217C\] {
    --tw-bg-opacity: 1;
    background-color: rgb(0 33 124 / var(--tw-bg-opacity, 1));
}
.bg-\[\#3C56D4\] {
    --tw-bg-opacity: 1;
    background-color: rgb(60 86 212 / var(--tw-bg-opacity, 1));
}
.bg-\[\#4a4d51\] {
    --tw-bg-opacity: 1;
    background-color: rgb(74 77 81 / var(--tw-bg-opacity, 1));
}
.bg-\[\#6f6e6e\] {
    --tw-bg-opacity: 1;
    background-color: rgb(111 110 110 / var(--tw-bg-opacity, 1));
}
.bg-\[\#7F95FF\] {
    --tw-bg-opacity: 1;
    background-color: rgb(127 149 255 / var(--tw-bg-opacity, 1));
}
.bg-\[\#D6DFE9\] {
    --tw-bg-opacity: 1;
    background-color: rgb(214 223 233 / var(--tw-bg-opacity, 1));
}
.bg-\[\#E1E8F2\] {
    --tw-bg-opacity: 1;
    background-color: rgb(225 232 242 / var(--tw-bg-opacity, 1));
}
.bg-\[\#F1F6FC\] {
    --tw-bg-opacity: 1;
    background-color: rgb(241 246 252 / var(--tw-bg-opacity, 1));
}
.bg-\[\#FAFAFA\] {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity, 1));
}
.bg-\[\#FF6029\] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 96 41 / var(--tw-bg-opacity, 1));
}
.bg-\[\#c5d2e4\] {
    --tw-bg-opacity: 1;
    background-color: rgb(197 210 228 / var(--tw-bg-opacity, 1));
}
.bg-\[\#eff6ff\] {
    --tw-bg-opacity: 1;
    background-color: rgb(239 246 255 / var(--tw-bg-opacity, 1));
}
.bg-\[\#fe561c\] {
    --tw-bg-opacity: 1;
    background-color: rgb(254 86 28 / var(--tw-bg-opacity, 1));
}
.bg-blue-water {
    --tw-bg-opacity: 1;
    background-color: rgb(204 229 255 / var(--tw-bg-opacity, 1));
}
.bg-danger {
    --tw-bg-opacity: 1;
    background-color: rgb(241 16 16 / var(--tw-bg-opacity, 1));
}
.bg-danger-120 {
    --tw-bg-opacity: 1;
    background-color: rgb(179 9 9 / var(--tw-bg-opacity, 1));
}
.bg-danger-old {
    --tw-bg-opacity: 1;
    background-color: rgb(205 33 38 / var(--tw-bg-opacity, 1));
}
.bg-gray-alice {
    --tw-bg-opacity: 1;
    background-color: rgb(243 247 251 / var(--tw-bg-opacity, 1));
}
.bg-gray-azureish {
    --tw-bg-opacity: 1;
    background-color: rgb(225 232 242 / var(--tw-bg-opacity, 1));
}
.bg-gray-gainsboro {
    --tw-bg-opacity: 1;
    background-color: rgb(214 223 233 / var(--tw-bg-opacity, 1));
}
.bg-gray-lotion {
    --tw-bg-opacity: 1;
    background-color: rgb(252 252 252 / var(--tw-bg-opacity, 1));
}
.bg-neutral-0 {
    --tw-bg-opacity: 1;
    background-color: rgb(240 245 251 / var(--tw-bg-opacity, 1));
}
.bg-neutral-70 {
    --tw-bg-opacity: 1;
    background-color: rgb(192 192 192 / var(--tw-bg-opacity, 1));
}
.bg-primary {
    --tw-bg-opacity: 1;
    background-color: rgb(60 86 212 / var(--tw-bg-opacity, 1));
}
.bg-primary-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(60 86 212 / var(--tw-bg-opacity, 1));
}
.bg-success {
    --tw-bg-opacity: 1;
    background-color: rgb(0 179 98 / var(--tw-bg-opacity, 1));
}
.bg-success-120 {
    --tw-bg-opacity: 1;
    background-color: rgb(0 132 71 / var(--tw-bg-opacity, 1));
}
.bg-transparent {
    background-color: transparent;
}
.bg-warning {
    --tw-bg-opacity: 1;
    background-color: rgb(255 185 0 / var(--tw-bg-opacity, 1));
}
.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}
.bg-white-ghost {
    --tw-bg-opacity: 1;
    background-color: rgb(248 251 254 / var(--tw-bg-opacity, 1));
}
.bg-\[linear-gradient\(180deg\2c \#F9FBFE_0\%\2c \#F1F6FC_100\%\)\] {
    background-image: linear-gradient(180deg,#F9FBFE 0%,#F1F6FC 100%);
}
.object-contain {
    -o-object-fit: contain;
       object-fit: contain;
}
.object-cover {
    -o-object-fit: cover;
       object-fit: cover;
}
.p-1 {
    padding: 0.25rem;
}
.p-2 {
    padding: 0.5rem;
}
.p-2\.5 {
    padding: 0.625rem;
}
.p-3 {
    padding: 0.75rem;
}
.p-3\.5 {
    padding: 0.875rem;
}
.p-4 {
    padding: 1rem;
}
.p-8 {
    padding: 2rem;
}
.p-\[10px\] {
    padding: 10px;
}
.p-\[12px_16px\] {
    padding: 12px 16px;
}
.p-\[24px\] {
    padding: 24px;
}
.p-\[24px_16px\] {
    padding: 24px 16px;
}
.p-\[2px\] {
    padding: 2px;
}
.p-\[3px\] {
    padding: 3px;
}
.p-\[8px_12px\] {
    padding: 8px 12px;
}
.\!px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.\!px-\[20px\] {
    padding-left: 20px !important;
    padding-right: 20px !important;
}
.px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}
.px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
}
.px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}
.px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}
.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
}
.px-\[15px\] {
    padding-left: 15px;
    padding-right: 15px;
}
.px-\[19px\] {
    padding-left: 19px;
    padding-right: 19px;
}
.px-\[5px\] {
    padding-left: 5px;
    padding-right: 5px;
}
.py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
}
.py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
.py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.\!pb-\[0px\] {
    padding-bottom: 0px !important;
}
.\!pb-\[5px\] {
    padding-bottom: 5px !important;
}
.\!pl-\[12px\] {
    padding-left: 12px !important;
}
.\!pr-\[12px\] {
    padding-right: 12px !important;
}
.\!pt-\[5px\] {
    padding-top: 5px !important;
}
.pb-2 {
    padding-bottom: 0.5rem;
}
.pb-4 {
    padding-bottom: 1rem;
}
.pb-6 {
    padding-bottom: 1.5rem;
}
.pb-\[28px\] {
    padding-bottom: 28px;
}
.pb-\[33\.33\%\] {
    padding-bottom: 33.33%;
}
.pb-\[3px\] {
    padding-bottom: 3px;
}
.pb-\[40px\] {
    padding-bottom: 40px;
}
.pb-\[80px\] {
    padding-bottom: 80px;
}
.pe-1 {
    padding-inline-end: 0.25rem;
}
.pe-3 {
    padding-inline-end: 0.75rem;
}
.ps-1 {
    padding-inline-start: 0.25rem;
}
.ps-3 {
    padding-inline-start: 0.75rem;
}
.pt-0 {
    padding-top: 0px;
}
.pt-10 {
    padding-top: 2.5rem;
}
.pt-16 {
    padding-top: 4rem;
}
.pt-2 {
    padding-top: 0.5rem;
}
.pt-3 {
    padding-top: 0.75rem;
}
.pt-32 {
    padding-top: 8rem;
}
.pt-4 {
    padding-top: 1rem;
}
.pt-6 {
    padding-top: 1.5rem;
}
.pt-8 {
    padding-top: 2rem;
}
.pt-\[8px\] {
    padding-top: 8px;
}
.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.text-justify {
    text-align: justify;
}
.text-start {
    text-align: start;
}
.text-end {
    text-align: end;
}
.font {
    font-family: "IBM Plex Sans Arabic", sans-serif;
}
.font-inter {
    font-family: "Inter", sans-serif;
}
.font-plex-arabic {
    font-family: "IBM Plex Sans Arabic", sans-serif;
}
.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
}
.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
}
.text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
}
.text-5xl {
    font-size: 3rem;
    line-height: 1;
}
.text-6xl {
    font-size: 3.75rem;
    line-height: 1;
}
.text-7xl {
    font-size: 4.5rem;
    line-height: 1;
}
.text-8xl {
    font-size: 6rem;
    line-height: 1;
}
.text-9xl {
    font-size: 8rem;
    line-height: 1;
}
.text-\[10px\] {
    font-size: 10px;
}
.text-\[11px\] {
    font-size: 11px;
}
.text-\[13px\] {
    font-size: 13px;
}
.text-\[14px\] {
    font-size: 14px;
}
.text-\[8px\] {
    font-size: 8px;
}
.text-base {
    font-size: 1rem;
    line-height: 1.5rem;
}
.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}
.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}
.text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
}
.font-bold {
    font-weight: 700;
}
.font-light {
    font-weight: 300;
}
.font-medium {
    font-weight: 500;
}
.font-normal {
    font-weight: 400;
}
.font-semibold {
    font-weight: 600;
}
.leading-4 {
    line-height: 1rem;
}
.leading-5 {
    line-height: 1.25rem;
}
.leading-6 {
    line-height: 1.5rem;
}
.leading-8 {
    line-height: 2rem;
}
.leading-\[10px\] {
    line-height: 10px;
}
.leading-\[12px\] {
    line-height: 12px;
}
.leading-\[16px\] {
    line-height: 16px;
}
.leading-\[24px\] {
    line-height: 24px;
}
.leading-\[28px\] {
    line-height: 28px;
}
.leading-\[32px\] {
    line-height: 32px;
}
.text-\[\#00B362\] {
    --tw-text-opacity: 1;
    color: rgb(0 179 98 / var(--tw-text-opacity, 1));
}
.text-\[\#0E5BC0\] {
    --tw-text-opacity: 1;
    color: rgb(14 91 192 / var(--tw-text-opacity, 1));
}
.text-\[\#0e5bc0\] {
    --tw-text-opacity: 1;
    color: rgb(14 91 192 / var(--tw-text-opacity, 1));
}
.text-\[\#154F9C\] {
    --tw-text-opacity: 1;
    color: rgb(21 79 156 / var(--tw-text-opacity, 1));
}
.text-\[\#154f9c\] {
    --tw-text-opacity: 1;
    color: rgb(21 79 156 / var(--tw-text-opacity, 1));
}
.text-\[\#231BFF\] {
    --tw-text-opacity: 1;
    color: rgb(35 27 255 / var(--tw-text-opacity, 1));
}
.text-\[\#3C56D4\] {
    --tw-text-opacity: 1;
    color: rgb(60 86 212 / var(--tw-text-opacity, 1));
}
.text-\[\#3c56d4\] {
    --tw-text-opacity: 1;
    color: rgb(60 86 212 / var(--tw-text-opacity, 1));
}
.text-\[\#484848\] {
    --tw-text-opacity: 1;
    color: rgb(72 72 72 / var(--tw-text-opacity, 1));
}
.text-\[\#4B5A6B\] {
    --tw-text-opacity: 1;
    color: rgb(75 90 107 / var(--tw-text-opacity, 1));
}
.text-\[\#697A8F\] {
    --tw-text-opacity: 1;
    color: rgb(105 122 143 / var(--tw-text-opacity, 1));
}
.text-\[\#96A4B8\] {
    --tw-text-opacity: 1;
    color: rgb(150 164 184 / var(--tw-text-opacity, 1));
}
.text-\[\#C0C0C0\] {
    --tw-text-opacity: 1;
    color: rgb(192 192 192 / var(--tw-text-opacity, 1));
}
.text-\[\#FE561C\] {
    --tw-text-opacity: 1;
    color: rgb(254 86 28 / var(--tw-text-opacity, 1));
}
.text-blue-old {
    --tw-text-opacity: 1;
    color: rgb(21 79 156 / var(--tw-text-opacity, 1));
}
.text-current {
    color: currentColor;
}
.text-danger {
    --tw-text-opacity: 1;
    color: rgb(241 16 16 / var(--tw-text-opacity, 1));
}
.text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}
.text-gray-causeway {
    --tw-text-opacity: 1;
    color: rgb(108 122 141 / var(--tw-text-opacity, 1));
}
.text-gray-text {
    --tw-text-opacity: 1;
    color: rgb(72 72 72 / var(--tw-text-opacity, 1));
}
.text-inherit {
    color: inherit;
}
.text-neutral-100 {
    --tw-text-opacity: 1;
    color: rgb(10 10 10 / var(--tw-text-opacity, 1));
}
.text-neutral-20 {
    --tw-text-opacity: 1;
    color: rgb(172 184 200 / var(--tw-text-opacity, 1));
}
.text-neutral-30 {
    --tw-text-opacity: 1;
    color: rgb(108 122 141 / var(--tw-text-opacity, 1));
}
.text-neutral-70 {
    --tw-text-opacity: 1;
    color: rgb(192 192 192 / var(--tw-text-opacity, 1));
}
.text-neutral-80 {
    --tw-text-opacity: 1;
    color: rgb(131 131 131 / var(--tw-text-opacity, 1));
}
.text-neutral-90 {
    --tw-text-opacity: 1;
    color: rgb(56 56 56 / var(--tw-text-opacity, 1));
}
.text-primary {
    --tw-text-opacity: 1;
    color: rgb(60 86 212 / var(--tw-text-opacity, 1));
}
.text-primary-120 {
    --tw-text-opacity: 1;
    color: rgb(7 65 142 / var(--tw-text-opacity, 1));
}
.text-secondary {
    --tw-text-opacity: 1;
    color: rgb(254 86 28 / var(--tw-text-opacity, 1));
}
.text-success {
    --tw-text-opacity: 1;
    color: rgb(0 179 98 / var(--tw-text-opacity, 1));
}
.text-warning {
    --tw-text-opacity: 1;
    color: rgb(255 185 0 / var(--tw-text-opacity, 1));
}
.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.underline {
    text-decoration-line: underline;
}
.line-through {
    text-decoration-line: line-through;
}
.decoration-solid {
    text-decoration-style: solid;
}
.decoration-1 {
    text-decoration-thickness: 1px;
}
.underline-offset-4 {
    text-underline-offset: 4px;
}
.opacity-0 {
    opacity: 0;
}
.opacity-100 {
    opacity: 1;
}
.opacity-\[0\.5993\] {
    opacity: 0.5993;
}
.shadow {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0_0_10px_\#bcbcbc66\] {
    --tw-shadow: 0 0 10px #bcbcbc66;
    --tw-shadow-colored: 0 0 10px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0_0_10px_rgba\(188\2c 188\2c 188\2c 0\.4\)\] {
    --tw-shadow: 0 0 10px rgba(188,188,188,0.4);
    --tw-shadow-colored: 0 0 10px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0_0_12px_0_rgba\(0\2c 0\2c 0\2c 0\.10\)\] {
    --tw-shadow: 0 0 12px 0 rgba(0,0,0,0.10);
    --tw-shadow-colored: 0 0 12px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0px_0px_12px_0px_rgba\(0\2c 0\2c 0\2c 0\.10\)\] {
    --tw-shadow: 0px 0px 12px 0px rgba(0,0,0,0.10);
    --tw-shadow-colored: 0px 0px 12px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0px_8px_12px_0px_rgba\(0\2c 0\2c 0\2c 0\.15\)\] {
    --tw-shadow: 0px 8px 12px 0px rgba(0,0,0,0.15);
    --tw-shadow-colored: 0px 8px 12px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-card {
    --tw-shadow: 0 0 12px 0 #0000001A;
    --tw-shadow-colored: 0 0 12px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.blur {
    --tw-blur: blur(8px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.transition-colors {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.duration-1000 {
    transition-duration: 1000ms;
}
.duration-200 {
    transition-duration: 200ms;
}
.duration-300 {
    transition-duration: 300ms;
}
.duration-500 {
    transition-duration: 500ms;
}
.duration-700 {
    transition-duration: 700ms;
}
.ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.material-symbols--add-2-rounded {
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='black' d='M12 21q-.425 0-.712-.288T11 20v-7H4q-.425 0-.712-.288T3 12t.288-.712T4 11h7V4q0-.425.288-.712T12 3t.713.288T13 4v7h7q.425 0 .713.288T21 12t-.288.713T20 13h-7v7q0 .425-.288.713T12 21'/%3E%3C/svg%3E");
}
.material-symbols--arrow-back-ios-new-rounded {
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='black' d='m9.55 12l7.35 7.35q.375.375.363.875t-.388.875t-.875.375t-.875-.375l-7.7-7.675q-.3-.3-.45-.675t-.15-.75t.15-.75t.45-.675l7.7-7.7q.375-.375.888-.363t.887.388t.375.875t-.375.875z'/%3E%3C/svg%3E");
}
.material-symbols--arrow-forward-ios-rounded {
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='black' d='m14.475 12l-7.35-7.35q-.375-.375-.363-.888t.388-.887t.888-.375t.887.375l7.675 7.7q.3.3.45.675t.15.75t-.15.75t-.45.675l-7.7 7.7q-.375.375-.875.363T7.15 21.1t-.375-.888t.375-.887z'/%3E%3C/svg%3E");
}
.material-symbols--car-tag-outline-rounded {
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='black' d='M6.5 16q.625 0 1.063-.437T8 14.5t-.437-1.062T6.5 13t-1.062.438T5 14.5t.438 1.063T6.5 16m9 0q.5 0 .9-.3t.525-.775q-.5-.1-.95-.338t-.825-.612l-.625-.625q-.25.2-.387.5T14 14.5q0 .625.438 1.063T15.5 16M4 17v-5zm-2-4.65q0-.175.025-.35t.075-.325L4.1 6q.15-.45.538-.725T5.5 5H8q.425 0 .712.288T9 6t-.288.713T8 7H5.85L4.8 10H11q.425 0 .713.288T12 11t-.288.713T11 12H4v5h14v-1q0-.425.288-.712T19 15t.713.288T20 16v3.5q0 .625-.437 1.063T18.5 21t-1.062-.437T17 19.5V19H5v.5q0 .625-.437 1.063T3.5 21t-1.062-.437T2 19.5zM15 6q.425 0 .713-.287T16 5t-.288-.712T15 4t-.712.288T14 5t.288.713T15 6m1.55 6.55l-5.1-5.1q-.2-.2-.325-.488T11 6.376V2.5q0-.625.438-1.062T12.5 1h3.875q.3 0 .588.125t.487.325l5.1 5.1q.425.425.425 1.063t-.425 1.062l-3.875 3.875q-.425.425-1.062.425t-1.063-.425m1.075-1.75L20.8 7.625L16.175 3H13v3.175zM16.9 6.9'/%3E%3C/svg%3E");
}
.material-symbols--check-circle-rounded {
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='black' d='m10.6 13.8l-2.15-2.15q-.275-.275-.7-.275t-.7.275t-.275.7t.275.7L9.9 15.9q.3.3.7.3t.7-.3l5.65-5.65q.275-.275.275-.7t-.275-.7t-.7-.275t-.7.275zM12 22q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22'/%3E%3C/svg%3E");
}
.material-symbols--check-rounded {
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='black' d='m9.55 15.15l8.475-8.475q.3-.3.7-.3t.7.3t.3.713t-.3.712l-9.175 9.2q-.3.3-.7.3t-.7-.3L4.55 13q-.3-.3-.288-.712t.313-.713t.713-.3t.712.3z'/%3E%3C/svg%3E");
}
.material-symbols--circle {
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='black' d='M12 22q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22'/%3E%3C/svg%3E");
}
.material-symbols--close-rounded {
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='black' d='m12 13.4l-4.9 4.9q-.275.275-.7.275t-.7-.275t-.275-.7t.275-.7l4.9-4.9l-4.9-4.9q-.275-.275-.275-.7t.275-.7t.7-.275t.7.275l4.9 4.9l4.9-4.9q.275-.275.7-.275t.7.275t.275.7t-.275.7L13.4 12l4.9 4.9q.275.275.275.7t-.275.7t-.7.275t-.7-.275z'/%3E%3C/svg%3E");
}
.material-symbols--dangerous-outline-rounded {
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='black' d='M9.075 21q-.4 0-.762-.15t-.638-.425l-4.1-4.1q-.275-.275-.425-.638T3 14.926v-5.85q0-.4.15-.762t.425-.638l4.1-4.1q.275-.275.638-.425T9.075 3h5.85q.4 0 .763.15t.637.425l4.1 4.1q.275.275.425.638t.15.762v5.85q0 .4-.15.763t-.425.637l-4.1 4.1q-.275.275-.638.425t-.762.15zm.025-2h5.8l4.1-4.1V9.1L14.9 5H9.1L5 9.1v5.8zm2.9-5.6l2.15 2.15q.275.275.7.275t.7-.275t.275-.7t-.275-.7L13.4 12l2.15-2.15q.275-.275.275-.7t-.275-.7t-.7-.275t-.7.275L12 10.6L9.85 8.45q-.275-.275-.7-.275t-.7.275t-.275.7t.275.7L10.6 12l-2.15 2.15q-.275.275-.275.7t.275.7t.7.275t.7-.275zm0-1.4'/%3E%3C/svg%3E");
}
.material-symbols--directions-car-outline-rounded {
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='black' d='M6 19v.5q0 .625-.437 1.063T4.5 21t-1.062-.437T3 19.5v-7.15q0-.175.025-.35t.075-.325L4.975 6.35q.2-.6.725-.975T6.875 5h10.25q.65 0 1.175.375t.725.975l1.875 5.325q.05.15.075.325t.025.35v7.15q0 .625-.437 1.063T19.5 21t-1.062-.437T18 19.5V19zm-.2-9h12.4l-1.05-3H6.85zM5 12v5zm2.5 4q.625 0 1.063-.437T9 14.5t-.437-1.062T7.5 13t-1.062.438T6 14.5t.438 1.063T7.5 16m9 0q.625 0 1.063-.437T18 14.5t-.437-1.062T16.5 13t-1.062.438T15 14.5t.438 1.063T16.5 16M5 17h14v-5H5z'/%3E%3C/svg%3E");
}
.material-symbols--error-rounded {
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='black' d='M12 17q.425 0 .713-.288T13 16t-.288-.712T12 15t-.712.288T11 16t.288.713T12 17m0-4q.425 0 .713-.288T13 12V8q0-.425-.288-.712T12 7t-.712.288T11 8v4q0 .425.288.713T12 13m0 9q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22'/%3E%3C/svg%3E");
}
.material-symbols--home-rounded {
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='black' d='M4 19v-9q0-.475.213-.9t.587-.7l6-4.5q.525-.4 1.2-.4t1.2.4l6 4.5q.375.275.588.7T20 10v9q0 .825-.588 1.413T18 21h-3q-.425 0-.712-.288T14 20v-5q0-.425-.288-.712T13 14h-2q-.425 0-.712.288T10 15v5q0 .425-.288.713T9 21H6q-.825 0-1.412-.587T4 19'/%3E%3C/svg%3E");
}
.material-symbols--keyboard-arrow-down-rounded {
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='black' d='M12 14.975q-.2 0-.375-.062T11.3 14.7l-4.6-4.6q-.275-.275-.275-.7t.275-.7t.7-.275t.7.275l3.9 3.9l3.9-3.9q.275-.275.7-.275t.7.275t.275.7t-.275.7l-4.6 4.6q-.15.15-.325.213t-.375.062'/%3E%3C/svg%3E");
}
.material-symbols--keyboard-arrow-up-rounded {
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='black' d='m12 10.8l-3.9 3.9q-.275.275-.7.275t-.7-.275t-.275-.7t.275-.7l4.6-4.6q.3-.3.7-.3t.7.3l4.6 4.6q.275.275.275.7t-.275.7t-.7.275t-.7-.275z'/%3E%3C/svg%3E");
}
.material-symbols--more-horiz {
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='black' d='M6 14q-.825 0-1.412-.587T4 12t.588-1.412T6 10t1.413.588T8 12t-.587 1.413T6 14m6 0q-.825 0-1.412-.587T10 12t.588-1.412T12 10t1.413.588T14 12t-.587 1.413T12 14m6 0q-.825 0-1.412-.587T16 12t.588-1.412T18 10t1.413.588T20 12t-.587 1.413T18 14'/%3E%3C/svg%3E");
}
.material-symbols--search-rounded {
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='black' d='M9.5 16q-2.725 0-4.612-1.888T3 9.5t1.888-4.612T9.5 3t4.613 1.888T16 9.5q0 1.1-.35 2.075T14.7 13.3l5.6 5.6q.275.275.275.7t-.275.7t-.7.275t-.7-.275l-5.6-5.6q-.75.6-1.725.95T9.5 16m0-2q1.875 0 3.188-1.312T14 9.5t-1.312-3.187T9.5 5T6.313 6.313T5 9.5t1.313 3.188T9.5 14'/%3E%3C/svg%3E");
}
.material-symbols--warning-rounded {
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='black' d='M2.725 21q-.275 0-.5-.137t-.35-.363t-.137-.488t.137-.512l9.25-16q.15-.25.388-.375T12 3t.488.125t.387.375l9.25 16q.15.25.138.513t-.138.487t-.35.363t-.5.137zM12 18q.425 0 .713-.288T13 17t-.288-.712T12 16t-.712.288T11 17t.288.713T12 18m0-3q.425 0 .713-.288T13 14v-3q0-.425-.288-.712T12 10t-.712.288T11 11v3q0 .425.288.713T12 15'/%3E%3C/svg%3E");
}
.\[border\:none\!important\] {
    border: none !important;
}

strong {
    font-weight: 700;
}

.blueBtn {
    background-color: var(--blueColor) !important;
    transition: 0.2s;
    border: solid 1px var(--blueColor) !important;
    box-sizing: border-box;
    color: #ffffff !important;
}

.blueBtn:hover {
    background-color: #104285 !important;
}

.blueBtn:active {
    background-color: #113c76 !important;
}

.greenBtn {
    background-color: #00b362 !important;
    transition: 0.2s;
    border: solid 1px #00b362 !important;
    box-sizing: border-box;
    color: #ffffff !important;
}

.greenBtn:hover {
    background-color: #049453 !important;
}

.greenBtn:active {
    background-color: #037b45 !important;
}

.greenBtn:disabled {
    opacity: 0.5;
}

.greenGrd {
    font-weight: normal;
    color: #00b362;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.redBtn22 {
    background-color: #ec6234;
    transition: 0.2s;
    border: solid 1px #ec6234;
    box-sizing: border-box;
    color: #ffffff;
}

.redBtn22:hover {
    background-color: #d65428;
}

.redBtn22:active {
    background-color: #cc4c21;
}

.blueTextBtn {
    background-color: #e2eaf4 !important;
    transition: 0.2s;
    border: solid 1px #e2eaf4 !important;
    box-sizing: border-box;
    color: #3f5776 !important;
}

.blueTextBtn:hover,
.blueTextBtn:active {
    background-color: #154f9c !important;
    color: #fff !important;
}

.whtBtn {
    background-color: transparent !important;
    transition: 0.2s;
    color: var(--blueColor) !important;
    border: solid 1px var(--blueColor) !important;
    box-sizing: border-box;
}

.whtBtn:hover {
    background-color: var(--blueColor) !important;
    color: #ffffff !important;
}

.whtBtn:active {
    background-color: #104285 !important;
    color: #ffffff !important;
}

.gryBtn {
    background-color: transparent !important;
    transition: 0.2s;
    color: #6c7a8d !important;
    border: solid 1px #6c7a8d !important;
    box-sizing: border-box;
}

.gryBtn:hover {
    background-color: #6c7a8d !important;
    color: #ffffff !important;
}

.gryBtn:active {
    background-color: #6c7a8d !important;
    color: #ffffff !important;
}

.big {
    color: #154f9c;
    font-size: 30px;
    line-height: 56px;
    font-weight: bold;
    position: relative;
    padding-bottom: 30px;
    text-align: center;
    word-wrap: break-word;
}

.big::after {
    /* content: ""; */
    width: 104px;
    height: 5px;
    background-color: #ec6234;
    position: absolute;
    left: calc(50% - 104px / 2);
    bottom: 0px;
}

.whtBtn {
    background-color: transparent !important;
    transition: 0.2s;
    color: var(--blueColor) !important;
    border: solid 1px var(--blueColor) !important;
}

.container {
    max-width: 2000px;
    width: 90%;
    margin: 0 auto;
}

.siteHeader > .innerHdr {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

body[dir="ltr"] .siteHeader > .innerHdr > ul > li:not(:first-child) {
    margin-left: 30px;
}

body[dir="ltr"],
body[dir="ltr"] * {
    font-family: Lato !important;
}

.webview:not(.IOS) [class*="backtnDT"],
.webview:not(.IOS) [class*="mobileBackBlueArr"] {
    display: none !important;
}


.webview [class*="InfoDetailsComponent-module__linkLikeName"] {
    /* pointer-events: none !important; */
}

.webview [class*="TrackingNoResult-module__container"] > p {
    display: none !important;
}

.webview [class*="TrackingNoResult-module__btn"]
    /* ,.webview svg[class*="ArrowType2SVG"], */
    /* .webview [class*="AfterSubmitComp-module__wrapper"] */
{
    display: none !important;
}

.closeWebView,
.webviewHEading,
.webview [class*="FilePreview-module__whtButton"] {
    display: none !important;
}

.webview .closeWebView {
    display: flex !important;
    position: fixed;
    z-index: 99999;
    left: 0;
    background: #154f9c;
    top: 83px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    box-sizing: border-box;
    padding: 5px 5px;
}

.webview div[class*="BottomSheetPopup-module__genralPopUpContent"] {
    height: 600px;
}

.webview div[class*="SharedPopUp-module__genralPopUpContent"] {
    height: 600px;
}

.webview .postViewContainer {
    padding-top: 40px;
}

body[dir="rtl"] .webview [class*="MobileHeader1"] [class*="ArrowType2SVG"] {
    transform: rotate(180deg);
}

.webview .webviewHEading {
    position: fixed;
    top: 0;
    z-index: 9;
    right: 0;
    display: block !important;
    width: 60px;
    height: 50px;
    background-color: #ffffff;
}

body[dir="rtl"] .webview .webviewHEading {
    right: 0;
}

body[dir="ltr"] .webview .webviewHEading {
    left: 0;
}

.webview [class*="BottomSheetPopup-module__genralModalbody"] {
    max-height: 600px;
    min-height: 600px;
}

.webview [class*="SharedPopUp-module__genralModalbody"] {
    max-height: 600px;
    min-height: 600px;
}

.rpv-core__text-layer {
    display: none !important;
}

.rpv-core__page-layer {
    direction: ltr !important;
}

div#fc_frame {
    background-color: #ffffff;
}

.webview div[class*="botomNavContainer"],
.webview #LineCamp,
.webview .breadCrumb2,
.webview:not(.isTracking) [class*="siteHeader"] {
    display: none !important;
}

.webview .hasEvents:not(.applyWebVevent) {
    pointer-events: none !important;
}

.MainContainerLO.webview .notWebViewBtn {
    display: none !important;
}

.MainContainerLO:not(.webview) .webviewBTN {
    display: none !important;
}

.MainContainerLO:not(.webview) .showWebView {
    display: none !important;
}

.MainContainerLO.webview .hideWebView {
    display: none !important;
}

.MainContainerLO.webview .webViewDangerousHTMLContainer a {
    pointer-events: none !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

/* General styling (optional) */
input[type="number"] {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none; /* Fallback for future-proofing */
}

.redBtn {
    background-color: #fe561c !important;
    transition: 0.2s;
    border: solid 1px #fe561c !important;
    box-sizing: border-box;
    color: #fff !important;
}

.redBtn:hover {
    background-color: #d65428 !important;
}

.redBtn:active {
    background-color: #d65428;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

span[class*="path"],
body[dir="ltr"] [class*=" icon-"],
body[dir="ltr"] [class*=" icon-"] *,
body[dir="ltr"] [class^="icon-"],
body[dir="ltr"] [class^="icon-"] * {
    font-family: icomoon !important;
}

img:not(.hasEvents) {
    pointer-events: none !important;
}

.greenTextBtn {
    color: #00b362;
    border: solid 1px #00b362;
    transition: 0.3s;
    box-sizing: border-box;
    cursor: pointer;
}

.greenTextBtn:hover {
    color: #ffffff;
    background-color: #00b362;
}

.videoWrapper {
    position: relative;
    padding-bottom: 34.1%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    border-radius: 8px;
}

.videoWrapper iframe,
.videoWrapper video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: fill;
    object-fit: fill;
    border-radius: 8px;
}

.hide {
    display: none !important;
}

.disableEvents,
.disableEvents * {
    pointer-events: none !important;
    text-decoration: none !important;
}

.highlighted-element {
  position: relative;
}

.highlighted-element::after {
  content: "";
  border-radius: 4px;
  border: 2px solid #3C56D4;
  display: block;
  position: absolute;
  width: 100%;
  height: calc( 100% + 1px );
  background: transparent;
  right: 0;
  top: -1px;
  pointer-events: none;
}

@media screen and (min-width: 1025px) {
    .extraWideContainer {
        max-width: 100% !important;
        width: 100% !important;
    }
}

@media (max-width: 1024px) {
    .highlighted-element{
    padding: 5px 10px !important;
  }
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
  }
  .big {
    font-size: 16px;
    line-height: 24px;
    text-align: start;
    padding: 0;}

    .big::after {
        width: 64px;
        height: 3px;
        left: calc(50% - 64px / 2);
        display: none;
    }
}

@media (max-width: 767px) {
    .h-mobile {
        display: none;
    }
}

@media (min-width: 768px) {
    .h-desktop {
        display: none;
    }
}

.errorText {
    color: #ec6234;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 4px;
    text-align: start;
}

.placeholder\:text-neutral-80::-moz-placeholder {
    --tw-text-opacity: 1;
    color: rgb(131 131 131 / var(--tw-text-opacity, 1));
}

.placeholder\:text-neutral-80::placeholder {
    --tw-text-opacity: 1;
    color: rgb(131 131 131 / var(--tw-text-opacity, 1));
}

.before\:absolute::before {
    content: var(--tw-content);
    position: absolute;
}

.after\:absolute::after {
    content: var(--tw-content);
    position: absolute;
}

.after\:bottom-\[-12px\]::after {
    content: var(--tw-content);
    bottom: -12px;
}

.after\:left-\[50\%\]::after {
    content: var(--tw-content);
    left: 50%;
}

.after\:block::after {
    content: var(--tw-content);
    display: block;
}

.after\:h-\[4px\]::after {
    content: var(--tw-content);
    height: 4px;
}

.after\:h-full::after {
    content: var(--tw-content);
    height: 100%;
}

.after\:w-\[140px\]::after {
    content: var(--tw-content);
    width: 140px;
}

.after\:w-full::after {
    content: var(--tw-content);
    width: 100%;
}

.after\:flex-shrink-0::after {
    content: var(--tw-content);
    flex-shrink: 0;
}

.after\:-translate-x-1\/2::after {
    content: var(--tw-content);
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:rounded-\[2px\]::after {
    content: var(--tw-content);
    border-radius: 2px;
}

.after\:bg-\[\#979797b3\]::after {
    content: var(--tw-content);
    background-color: #979797b3;
}

.after\:bg-\[\#FE561C\]::after {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(254 86 28 / var(--tw-bg-opacity, 1));
}

.after\:content-\[\'\'\]::after {
    --tw-content: '';
    content: var(--tw-content);
}

.focus-within\:border-danger:focus-within {
    --tw-border-opacity: 1;
    border-color: rgb(241 16 16 / var(--tw-border-opacity, 1));
}

.focus-within\:border-primary:focus-within {
    --tw-border-opacity: 1;
    border-color: rgb(60 86 212 / var(--tw-border-opacity, 1));
}

.focus-within\:border-success:focus-within {
    --tw-border-opacity: 1;
    border-color: rgb(0 179 98 / var(--tw-border-opacity, 1));
}

.focus-within\:border-warning:focus-within {
    --tw-border-opacity: 1;
    border-color: rgb(255 185 0 / var(--tw-border-opacity, 1));
}

.focus-within\:border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}

.hover\:border-danger-110:hover {
    --tw-border-opacity: 1;
    border-color: rgb(210 12 12 / var(--tw-border-opacity, 1));
}

.hover\:border-primary:hover {
    --tw-border-opacity: 1;
    border-color: rgb(60 86 212 / var(--tw-border-opacity, 1));
}

.hover\:border-primary-110:hover {
    --tw-border-opacity: 1;
    border-color: rgb(11 78 167 / var(--tw-border-opacity, 1));
}

.hover\:border-success-110:hover {
    --tw-border-opacity: 1;
    border-color: rgb(0 155 84 / var(--tw-border-opacity, 1));
}

.hover\:border-transparent:hover {
    border-color: transparent;
}

.hover\:border-warning-110:hover {
    --tw-border-opacity: 1;
    border-color: rgb(222 160 0 / var(--tw-border-opacity, 1));
}

.hover\:bg-danger-110:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(210 12 12 / var(--tw-bg-opacity, 1));
}

.hover\:bg-neutral-0:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(240 245 251 / var(--tw-bg-opacity, 1));
}

.hover\:bg-primary-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(60 86 212 / var(--tw-bg-opacity, 1));
}

.hover\:bg-primary-110:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(11 78 167 / var(--tw-bg-opacity, 1));
}

.hover\:bg-primary-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(212 222 250 / var(--tw-bg-opacity, 1));
}

.hover\:bg-success-110:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(0 155 84 / var(--tw-bg-opacity, 1));
}

.hover\:bg-white-ghost:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(248 251 254 / var(--tw-bg-opacity, 1));
}

.hover\:text-neutral-80:hover {
    --tw-text-opacity: 1;
    color: rgb(131 131 131 / var(--tw-text-opacity, 1));
}

.hover\:text-primary:hover {
    --tw-text-opacity: 1;
    color: rgb(60 86 212 / var(--tw-text-opacity, 1));
}

.hover\:text-primary-110:hover {
    --tw-text-opacity: 1;
    color: rgb(11 78 167 / var(--tw-text-opacity, 1));
}

.hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.hover\:opacity-85:hover {
    opacity: 0.85;
}

.focus\:border-danger:focus {
    --tw-border-opacity: 1;
    border-color: rgb(241 16 16 / var(--tw-border-opacity, 1));
}

.focus\:border-primary:focus {
    --tw-border-opacity: 1;
    border-color: rgb(60 86 212 / var(--tw-border-opacity, 1));
}

.focus\:border-success:focus {
    --tw-border-opacity: 1;
    border-color: rgb(0 179 98 / var(--tw-border-opacity, 1));
}

.focus\:border-warning:focus {
    --tw-border-opacity: 1;
    border-color: rgb(255 185 0 / var(--tw-border-opacity, 1));
}

.focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}

.focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.focus-visible\:outline-none:focus-visible {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.focus-visible\:ring-2:focus-visible {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:ring-offset-2:focus-visible {
    --tw-ring-offset-width: 2px;
}

.active\:bg-danger-120:active {
    --tw-bg-opacity: 1;
    background-color: rgb(179 9 9 / var(--tw-bg-opacity, 1));
}

.active\:bg-primary-100:active {
    --tw-bg-opacity: 1;
    background-color: rgb(60 86 212 / var(--tw-bg-opacity, 1));
}

.active\:bg-primary-120:active {
    --tw-bg-opacity: 1;
    background-color: rgb(7 65 142 / var(--tw-bg-opacity, 1));
}

.active\:bg-primary-50:active {
    --tw-bg-opacity: 1;
    background-color: rgb(212 222 250 / var(--tw-bg-opacity, 1));
}

.active\:bg-success-120:active {
    --tw-bg-opacity: 1;
    background-color: rgb(0 132 71 / var(--tw-bg-opacity, 1));
}

.active\:text-primary-120:active {
    --tw-text-opacity: 1;
    color: rgb(7 65 142 / var(--tw-text-opacity, 1));
}

.active\:text-white:active {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.disabled\:pointer-events-none:disabled {
    pointer-events: none;
}

.disabled\:cursor-not-allowed:disabled {
    cursor: not-allowed;
}

.disabled\:border-neutral-60:disabled {
    --tw-border-opacity: 1;
    border-color: rgb(224 224 224 / var(--tw-border-opacity, 1));
}

.disabled\:border-neutral-70:disabled {
    --tw-border-opacity: 1;
    border-color: rgb(192 192 192 / var(--tw-border-opacity, 1));
}

.disabled\:bg-neutral-60:disabled {
    --tw-bg-opacity: 1;
    background-color: rgb(224 224 224 / var(--tw-bg-opacity, 1));
}

.disabled\:text-neutral-70:disabled {
    --tw-text-opacity: 1;
    color: rgb(192 192 192 / var(--tw-text-opacity, 1));
}

.disabled\:opacity-50:disabled {
    opacity: 0.5;
}

.disabled\:hover\:border-neutral-70:hover:disabled {
    --tw-border-opacity: 1;
    border-color: rgb(192 192 192 / var(--tw-border-opacity, 1));
}

.group:focus-within .group-focus-within\:text-danger {
    --tw-text-opacity: 1;
    color: rgb(241 16 16 / var(--tw-text-opacity, 1));
}

.group:focus-within .group-focus-within\:text-primary {
    --tw-text-opacity: 1;
    color: rgb(60 86 212 / var(--tw-text-opacity, 1));
}

.group:focus-within .group-focus-within\:text-secondary {
    --tw-text-opacity: 1;
    color: rgb(254 86 28 / var(--tw-text-opacity, 1));
}

.group:focus-within .group-focus-within\:text-success {
    --tw-text-opacity: 1;
    color: rgb(0 179 98 / var(--tw-text-opacity, 1));
}

.group:focus-within .group-focus-within\:text-warning {
    --tw-text-opacity: 1;
    color: rgb(255 185 0 / var(--tw-text-opacity, 1));
}

.group:focus-within .group-focus-within\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.group:hover .group-hover\:text-danger {
    --tw-text-opacity: 1;
    color: rgb(241 16 16 / var(--tw-text-opacity, 1));
}

.group:hover .group-hover\:text-primary {
    --tw-text-opacity: 1;
    color: rgb(60 86 212 / var(--tw-text-opacity, 1));
}

.group:hover .group-hover\:text-primary-110 {
    --tw-text-opacity: 1;
    color: rgb(11 78 167 / var(--tw-text-opacity, 1));
}

.group:hover .group-hover\:text-secondary {
    --tw-text-opacity: 1;
    color: rgb(254 86 28 / var(--tw-text-opacity, 1));
}

.group:hover .group-hover\:text-success {
    --tw-text-opacity: 1;
    color: rgb(0 179 98 / var(--tw-text-opacity, 1));
}

.group:hover .group-hover\:text-warning {
    --tw-text-opacity: 1;
    color: rgb(255 185 0 / var(--tw-text-opacity, 1));
}

.group:hover .group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.peer:checked ~ .peer-checked\:block {
    display: block;
}

@keyframes blink {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
}

.aria-selected\:animate-blink[aria-selected="true"] {
    animation: blink 0.5s linear 4;
}

.aria-selected\:border[aria-selected="true"] {
    border-width: 1px;
}

.aria-selected\:border-solid[aria-selected="true"] {
    border-style: solid;
}

.aria-selected\:border-primary[aria-selected="true"] {
    --tw-border-opacity: 1;
    border-color: rgb(60 86 212 / var(--tw-border-opacity, 1));
}

.data-\[state\=checked\]\:translate-x-5[data-state="checked"] {
    --tw-translate-x: 1.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[state\=unchecked\]\:translate-x-0[data-state="unchecked"] {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[state\=\'checked\'\]\:border-primary[data-state='checked'] {
    --tw-border-opacity: 1;
    border-color: rgb(60 86 212 / var(--tw-border-opacity, 1));
}

.data-\[state\=\'checked\'\]\:bg-primary[data-state='checked'] {
    --tw-bg-opacity: 1;
    background-color: rgb(60 86 212 / var(--tw-bg-opacity, 1));
}

.data-\[state\=checked\]\:bg-primary[data-state="checked"] {
    --tw-bg-opacity: 1;
    background-color: rgb(60 86 212 / var(--tw-bg-opacity, 1));
}

.data-\[state\=unchecked\]\:bg-neutral-10[data-state="unchecked"] {
    --tw-bg-opacity: 1;
    background-color: rgb(214 223 233 / var(--tw-bg-opacity, 1));
}

.data-\[state\=\'unchecked\'\]\:hover\:border-primary-110:hover[data-state='unchecked'] {
    --tw-border-opacity: 1;
    border-color: rgb(11 78 167 / var(--tw-border-opacity, 1));
}

.data-\[state\=\'unchecked\'\]\:hover\:bg-neutral-0:hover[data-state='unchecked'] {
    --tw-bg-opacity: 1;
    background-color: rgb(240 245 251 / var(--tw-bg-opacity, 1));
}

.data-\[state\=checked\]\:hover\:bg-primary-110:hover[data-state="checked"] {
    --tw-bg-opacity: 1;
    background-color: rgb(11 78 167 / var(--tw-bg-opacity, 1));
}

.data-\[state\=unchecked\]\:hover\:bg-neutral-20:hover[data-state="unchecked"] {
    --tw-bg-opacity: 1;
    background-color: rgb(172 184 200 / var(--tw-bg-opacity, 1));
}

.data-\[state\=checked\]\:disabled\:bg-neutral-70:disabled[data-state="checked"] {
    --tw-bg-opacity: 1;
    background-color: rgb(192 192 192 / var(--tw-bg-opacity, 1));
}

.data-\[state\=unchecked\]\:disabled\:bg-neutral-70:disabled[data-state="unchecked"] {
    --tw-bg-opacity: 1;
    background-color: rgb(192 192 192 / var(--tw-bg-opacity, 1));
}

.disabled\:data-\[state\=\'unchecked\'\]\:hover\:border-neutral-70:hover[data-state='unchecked']:disabled {
    --tw-border-opacity: 1;
    border-color: rgb(192 192 192 / var(--tw-border-opacity, 1));
}

.data-\[state\=unchecked\]\:disabled\:hover\:bg-neutral-70:hover:disabled[data-state="unchecked"] {
    --tw-bg-opacity: 1;
    background-color: rgb(192 192 192 / var(--tw-bg-opacity, 1));
}

html[lang*="ar"] .ar\:left-3 {
    left: 0.75rem;
}

html[lang*="ar"] .ar\:left-4 {
    left: 1rem;
}

html[lang*="ar"] .ar\:left-6 {
    left: 1.5rem;
}

html[lang*="ar"] .ar\:left-\[-8px\] {
    left: -8px;
}

html[lang*="ar"] .ar\:left-\[24px\] {
    left: 24px;
}

html[lang*="ar"] .ar\:left-\[5px\] {
    left: 5px;
}

html[lang*="ar"] .ar\:right-0 {
    right: 0px;
}

html[lang*="ar"] .ar\:right-6 {
    right: 1.5rem;
}

html[lang*="ar"] .ar\:right-\[5px\] {
    right: 5px;
}

html[lang*="ar"] .ar\:mr-auto {
    margin-right: auto;
}

html[lang*="ar"] .ar\:rotate-180 {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

html[lang*="ar"] .ar\:gap-4 {
    gap: 1rem;
}

html[lang*="ar"] .ar\:text-right {
    text-align: right;
}

html[lang*="ar"] .ar\:font-plex-arabic {
    font-family: "IBM Plex Sans Arabic", sans-serif;
}

html[lang*="ar"] .ar\:\[transform\:rotateY\(180deg\)\] {
    transform: rotateY(180deg);
}

html[lang*="en"] .en\:left-0 {
    left: 0px;
}

html[lang*="en"] .en\:left-6 {
    left: 1.5rem;
}

html[lang*="en"] .en\:left-\[5px\] {
    left: 5px;
}

html[lang*="en"] .en\:right-3 {
    right: 0.75rem;
}

html[lang*="en"] .en\:right-4 {
    right: 1rem;
}

html[lang*="en"] .en\:right-\[-8px\] {
    right: -8px;
}

html[lang*="en"] .en\:right-\[24px\] {
    right: 24px;
}

html[lang*="en"] .en\:right-\[5px\] {
    right: 5px;
}

html[lang*="en"] .en\:ml-auto {
    margin-left: auto;
}

html[lang*="en"] .en\:rotate-180 {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

html[lang*="en"] .en\:transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

html[lang*="en"] .en\:gap-2 {
    gap: 0.5rem;
}

html[lang*="en"] .en\:font-inter {
    font-family: "Inter", sans-serif;
}

@media not all and (min-width: 768px) {
    .max-md\:-mx-4 {
        margin-left: -1rem;
        margin-right: -1rem;
    }
    .max-md\:mx-auto {
        margin-left: auto;
        margin-right: auto;
    }
    .max-md\:\!mb-0 {
        margin-bottom: 0px !important;
    }
    .max-md\:mb-0 {
        margin-bottom: 0px;
    }
    .max-md\:mt-0 {
        margin-top: 0px;
    }
    .max-md\:mt-4 {
        margin-top: 1rem;
    }
    .max-md\:mt-\[10px\] {
        margin-top: 10px;
    }
    .max-md\:mt-\[32px\] {
        margin-top: 32px;
    }
    .max-md\:h-\[48px\] {
        height: 48px;
    }
    .max-md\:h-\[56px\] {
        height: 56px;
    }
    .max-md\:w-\[48px\] {
        width: 48px;
    }
    .max-md\:w-\[80px\] {
        width: 80px;
    }
    .max-md\:w-\[90\%\] {
        width: 90%;
    }
    .max-md\:flex-col {
        flex-direction: column;
    }
    .max-md\:flex-nowrap {
        flex-wrap: nowrap;
    }
    .max-md\:overflow-x-scroll {
        overflow-x: scroll;
    }
    .max-md\:rounded-\[8px\] {
        border-radius: 8px;
    }
    .max-md\:\!px-5 {
        padding-left: 1.25rem !important;
        padding-right: 1.25rem !important;
    }
    .max-md\:px-4 {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .max-md\:px-5 {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
    .max-md\:py-0 {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .max-md\:pb-\[44\.33\%\] {
        padding-bottom: 44.33%;
    }
    .max-md\:text-lg {
        font-size: 1.125rem;
        line-height: 1.75rem;
    }
    .max-md\:text-xs {
        font-size: 0.75rem;
        line-height: 1rem;
    }
    .max-md\:font-semibold {
        font-weight: 600;
    }
    .max-md\:leading-\[24px\] {
        line-height: 24px;
    }
    .max-md\:shadow-none {
        --tw-shadow: 0 0 #0000;
        --tw-shadow-colored: 0 0 #0000;
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
}

@media not all and (min-width: 640px) {
    .max-sm\:block {
        display: block;
    }
    .max-sm\:text-center {
        text-align: center;
    }
}

@media (min-width: 640px) {
    .sm\:w-\[calc\(100\%\/4-0\.78em\)\] {
        width: calc(100% / 4 - 0.78em);
    }
    .sm\:justify-start {
        justify-content: flex-start;
    }
    .sm\:text-2xl {
        font-size: 1.5rem;
        line-height: 2rem;
    }
    .sm\:font-bold {
        font-weight: 700;
    }
    .sm\:leading-\[32px\] {
        line-height: 32px;
    }
}

@media (min-width: 768px) {
    .md\:right-\[50\%\] {
        right: 50%;
    }
    .md\:top-\[126px\] {
        top: 126px;
    }
    .md\:top-\[60px\] {
        top: 60px;
    }
    .md\:m-auto {
        margin: auto;
    }
    .md\:\!mt-\[72px\] {
        margin-top: 72px !important;
    }
    .md\:mb-0 {
        margin-bottom: 0px;
    }
    .md\:mb-8 {
        margin-bottom: 2rem;
    }
    .md\:mt-0 {
        margin-top: 0px;
    }
    .md\:mt-10 {
        margin-top: 2.5rem;
    }
    .md\:mt-6 {
        margin-top: 1.5rem;
    }
    .md\:mt-8 {
        margin-top: 2rem;
    }
    .md\:block {
        display: block;
    }
    .md\:hidden {
        display: none;
    }
    .md\:h-\[265px\] {
        height: 265px;
    }
    .md\:h-\[446px\] {
        height: 446px;
    }
    .md\:w-\[318px\] {
        width: 318px;
    }
    .md\:w-\[50\%\] {
        width: 50%;
    }
    .md\:w-\[500px\] {
        width: 500px;
    }
    .md\:w-\[calc\(100\%\/4-15px\)\] {
        width: calc(100% / 4 - 15px);
    }
    .md\:min-w-\[567px\] {
        min-width: 567px;
    }
    .md\:min-w-\[calc\(100\%\/4\)\] {
        min-width: calc(100% / 4);
    }
    .md\:\!max-w-\[1150px\] {
        max-width: 1150px !important;
    }
    .md\:max-w-\[100px\] {
        max-width: 100px;
    }
    .md\:max-w-\[735px\] {
        max-width: 735px;
    }
    .md\:max-w-\[767px\] {
        max-width: 767px;
    }
    .md\:max-w-\[calc\(100\%\/4\)\] {
        max-width: calc(100% / 4);
    }
    .md\:flex-\[20\%\] {
        flex: 20%;
    }
    .md\:flex-\[80\%\] {
        flex: 80%;
    }
    .md\:shrink-0 {
        flex-shrink: 0;
    }
    .md\:translate-x-1\/2 {
        --tw-translate-x: 50%;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }
    .md\:transform {
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }
    .md\:scroll-mt-\[220px\] {
        scroll-margin-top: 220px;
    }
    .md\:justify-center {
        justify-content: center;
    }
    .md\:gap-4 {
        gap: 1rem;
    }
    .md\:gap-\[15px\] {
        gap: 15px;
    }
    .md\:gap-\[21px\] {
        gap: 21px;
    }
    .md\:overflow-hidden {
        overflow: hidden;
    }
    .md\:overflow-x-visible {
        overflow-x: visible;
    }
    .md\:text-ellipsis {
        text-overflow: ellipsis;
    }
    .md\:whitespace-nowrap {
        white-space: nowrap;
    }
    .md\:rounded-\[16px\] {
        border-radius: 16px;
    }
    .md\:rounded-t-lg {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
    }
    .md\:bg-gray-alice {
        --tw-bg-opacity: 1;
        background-color: rgb(243 247 251 / var(--tw-bg-opacity, 1));
    }
    .md\:bg-white {
        --tw-bg-opacity: 1;
        background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
    }
    .md\:p-4 {
        padding: 1rem;
    }
    .md\:px-4 {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .md\:py-3 {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
    }
    .md\:pb-0 {
        padding-bottom: 0px;
    }
    .md\:pb-10 {
        padding-bottom: 2.5rem;
    }
    .md\:pb-6 {
        padding-bottom: 1.5rem;
    }
    .md\:pb-8 {
        padding-bottom: 2rem;
    }
    .md\:pt-4 {
        padding-top: 1rem;
    }
    .md\:pt-6 {
        padding-top: 1.5rem;
    }
    .md\:text-center {
        text-align: center;
    }
    .md\:text-2xl {
        font-size: 1.5rem;
        line-height: 2rem;
    }
    .md\:text-3xl {
        font-size: 1.875rem;
        line-height: 2.25rem;
    }
    .md\:text-\[18px\] {
        font-size: 18px;
    }
    .md\:text-base {
        font-size: 1rem;
        line-height: 1.5rem;
    }
    .md\:text-lg {
        font-size: 1.125rem;
        line-height: 1.75rem;
    }
    .md\:text-xl {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
    .md\:leading-\[24px\] {
        line-height: 24px;
    }
    .md\:after\:fixed::after {
        content: var(--tw-content);
        position: fixed;
    }
    .md\:after\:left-0::after {
        content: var(--tw-content);
        left: 0px;
    }
    .md\:after\:top-0::after {
        content: var(--tw-content);
        top: 0px;
    }
    .md\:after\:z-\[-1\]::after {
        content: var(--tw-content);
        z-index: -1;
    }
    .md\:after\:block::after {
        content: var(--tw-content);
        display: block;
    }
    .md\:after\:h-full::after {
        content: var(--tw-content);
        height: 100%;
    }
    .md\:after\:w-full::after {
        content: var(--tw-content);
        width: 100%;
    }
    .md\:after\:bg-\[\#F4F7FB\;\]::after {
        content: var(--tw-content);
        background-color: #F4F7FB;;
    }
    .md\:after\:content-\[\'\'\]::after {
        --tw-content: '';
        content: var(--tw-content);
    }
    html[lang*="ar"] .md\:ar\:right-\[calc\(145px\+50\%-250px\)\] {
        right: calc(145px + 50% - 250px);
    }
    html[lang*="ar"] .md\:ar\:right-\[calc\(50\%-250px\)\] {
        right: calc(50% - 250px);
    }
    html[lang*="en"] .md\:en\:left-\[calc\(145px\+50\%-250px\)\] {
        left: calc(145px + 50% - 250px);
    }
}

@media (min-width: 1024px) {
    .lg\:z-\[2\] {
        z-index: 2;
    }
    .lg\:mx-auto {
        margin-left: auto;
        margin-right: auto;
    }
    .lg\:ms-auto {
        margin-inline-start: auto;
    }
    .lg\:mt-0 {
        margin-top: 0px;
    }
    .lg\:mt-20 {
        margin-top: 5rem;
    }
    .lg\:mt-6 {
        margin-top: 1.5rem;
    }
    .lg\:mt-8 {
        margin-top: 2rem;
    }
    .lg\:block {
        display: block;
    }
    .lg\:flex {
        display: flex;
    }
    .lg\:hidden {
        display: none;
    }
    .lg\:min-h-\[calc\(100vh-41px\)\] {
        min-height: calc(100vh - 41px);
    }
    .lg\:min-h-\[calc\(100vh-70px\)\] {
        min-height: calc(100vh - 70px);
    }
    .lg\:min-h-\[unset\] {
        min-height: unset;
    }
    .lg\:w-\[172px\] {
        width: 172px;
    }
    .lg\:min-w-\[440px\] {
        min-width: 440px;
    }
    .lg\:min-w-\[unset\] {
        min-width: unset;
    }
    .lg\:max-w-\[345px\] {
        max-width: 345px;
    }
    .lg\:max-w-\[350px\] {
        max-width: 350px;
    }
    .lg\:max-w-\[370px\] {
        max-width: 370px;
    }
    .lg\:max-w-\[480px\] {
        max-width: 480px;
    }
    .lg\:max-w-\[665px\] {
        max-width: 665px;
    }
    .lg\:max-w-\[740px\] {
        max-width: 740px;
    }
    .lg\:max-w-\[900px\] {
        max-width: 900px;
    }
    .lg\:max-w-\[992px\] {
        max-width: 992px;
    }
    .lg\:flex-col {
        flex-direction: column;
    }
    .lg\:justify-center {
        justify-content: center;
    }
    .lg\:gap-0 {
        gap: 0px;
    }
    .lg\:gap-10 {
        gap: 2.5rem;
    }
    .lg\:gap-2 {
        gap: 0.5rem;
    }
    .lg\:gap-\[72px\] {
        gap: 72px;
    }
    .lg\:overflow-hidden {
        overflow: hidden;
    }
    .lg\:rounded-2xl {
        border-radius: 1rem;
    }
    .lg\:rounded-3xl {
        border-radius: 1.5rem;
    }
    .lg\:rounded-lg {
        border-radius: 0.5rem;
    }
    .lg\:border {
        border-width: 1px;
    }
    .lg\:border-0 {
        border-width: 0px;
    }
    .lg\:border-solid {
        border-style: solid;
    }
    .lg\:border-gray-gainsboro {
        --tw-border-opacity: 1;
        border-color: rgb(214 223 233 / var(--tw-border-opacity, 1));
    }
    .lg\:border-gray-lotion {
        --tw-border-opacity: 1;
        border-color: rgb(252 252 252 / var(--tw-border-opacity, 1));
    }
    .lg\:bg-\[\#FAFCFF\] {
        --tw-bg-opacity: 1;
        background-color: rgb(250 252 255 / var(--tw-bg-opacity, 1));
    }
    .lg\:bg-transparent {
        background-color: transparent;
    }
    .lg\:bg-white {
        --tw-bg-opacity: 1;
        background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
    }
    .lg\:p-0 {
        padding: 0px;
    }
    .lg\:p-10 {
        padding: 2.5rem;
    }
    .lg\:px-0 {
        padding-left: 0px;
        padding-right: 0px;
    }
    .lg\:px-16 {
        padding-left: 4rem;
        padding-right: 4rem;
    }
    .lg\:px-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .lg\:py-10 {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }
    .lg\:py-8 {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .lg\:pb-16 {
        padding-bottom: 4rem;
    }
    .lg\:pt-12 {
        padding-top: 3rem;
    }
    .lg\:pt-14 {
        padding-top: 3.5rem;
    }
    .lg\:pt-20 {
        padding-top: 5rem;
    }
    .lg\:text-center {
        text-align: center;
    }
    .lg\:shadow-xl {
        --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
        --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
    .lg\:before\:absolute::before {
        content: var(--tw-content);
        position: absolute;
    }
    .lg\:before\:left-0::before {
        content: var(--tw-content);
        left: 0px;
    }
    .lg\:before\:top-0::before {
        content: var(--tw-content);
        top: 0px;
    }
    .lg\:before\:z-\[1\]::before {
        content: var(--tw-content);
        z-index: 1;
    }
    .lg\:before\:block::before {
        content: var(--tw-content);
        display: block;
    }
    .lg\:before\:h-\[340px\]::before {
        content: var(--tw-content);
        height: 340px;
    }
    .lg\:before\:w-full::before {
        content: var(--tw-content);
        width: 100%;
    }
    .lg\:before\:bg-white::before {
        content: var(--tw-content);
        --tw-bg-opacity: 1;
        background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
    }
    .lg\:before\:content-\[\'\'\]::before {
        --tw-content: '';
        content: var(--tw-content);
    }
}

@media (min-width: 1280px) {
    .xl\:block {
        display: block;
    }
    .xl\:hidden {
        display: none;
    }
    .xl\:min-h-\[100vh-72px\] {
        min-height: 100vh-72px;
    }
    .xl\:max-w-\[1130px\] {
        max-width: 1130px;
    }
    .xl\:max-w-\[1158px\] {
        max-width: 1158px;
    }
    .xl\:px-4 {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

@media (min-width: 1536px) {
    .\32xl\:max-w-\[1330px\] {
        max-width: 1330px;
    }
}

.ltr\:right-6:where([dir="ltr"], [dir="ltr"] *) {
    right: 1.5rem;
}

.ltr\:rotate-180:where([dir="ltr"], [dir="ltr"] *) {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\[\&\:\:-moz-progress-bar\]\:bg-primary::-moz-progress-bar {
    --tw-bg-opacity: 1;
    background-color: rgb(60 86 212 / var(--tw-bg-opacity, 1));
}

.\[\&\:\:-webkit-progress-bar\]\:rounded-full::-webkit-progress-bar {
    border-radius: 9999px;
}

.\[\&\:\:-webkit-progress-bar\]\:bg-gray-gainsboro::-webkit-progress-bar {
    --tw-bg-opacity: 1;
    background-color: rgb(214 223 233 / var(--tw-bg-opacity, 1));
}

.\[\&\:\:-webkit-progress-value\]\:rounded-full::-webkit-progress-value {
    border-radius: 9999px;
}

.\[\&\:\:-webkit-progress-value\]\:bg-primary::-webkit-progress-value {
    --tw-bg-opacity: 1;
    background-color: rgb(60 86 212 / var(--tw-bg-opacity, 1));
}

@media not all and (min-width: 768px) {
    .max-md\:\[\&\>\*\:nth-child\(n\+5\)\]\:hidden>*:nth-child(n+5) {
        display: none;
    }
}

.\[\&_\.indicator\]\:data-\[disabled\]\:border-neutral-70[data-disabled] .indicator {
    --tw-border-opacity: 1;
    border-color: rgb(192 192 192 / var(--tw-border-opacity, 1));
}

.\[\&_\.indicator\]\:data-\[disabled\]\:text-neutral-70[data-disabled] .indicator {
    --tw-text-opacity: 1;
    color: rgb(192 192 192 / var(--tw-text-opacity, 1));
}

.\[\&_\.indicator\]\:data-\[state\=unchecked\]\:data-\[disabled\]\:hover\:border-neutral-70:hover[data-disabled][data-state="unchecked"] .indicator {
    --tw-border-opacity: 1;
    border-color: rgb(192 192 192 / var(--tw-border-opacity, 1));
}

.\[\&_\.indicator\]\:data-\[state\=unchecked\]\:hover\:border-primary-110:hover[data-state="unchecked"] .indicator {
    --tw-border-opacity: 1;
    border-color: rgb(11 78 167 / var(--tw-border-opacity, 1));
}

.\[\&_\.indicator\]\:data-\[state\=unchecked\]\:data-\[disabled\]\:hover\:bg-white:hover[data-disabled][data-state="unchecked"] .indicator {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.\[\&_\.indicator\]\:data-\[state\=unchecked\]\:hover\:bg-neutral-0:hover[data-state="unchecked"] .indicator {
    --tw-bg-opacity: 1;
    background-color: rgb(240 245 251 / var(--tw-bg-opacity, 1));
}

.\[\&_label\]\:cursor-pointer label {
    cursor: pointer;
}

.\[\&_label\]\:data-\[disabled\]\:cursor-not-allowed[data-disabled] label {
    cursor: not-allowed;
}

.\[\&_label\]\:data-\[disabled\]\:text-neutral-70[data-disabled] label {
    --tw-text-opacity: 1;
    color: rgb(192 192 192 / var(--tw-text-opacity, 1));
}


