/** Includes Color Variables if any & root variables and theme vars*/
:root {
  --vh: 60vh;
  --blueColor: #154f9c;
  --lightGray: #6c7a8d;
  --postFixedButtonsNumer: 1;
  --postFixedButtonsNumerUser: 1;
  --spacing-unit-1: 20px;
  --spacing-unit-2: 10px;
  --spacing-unit-3: 30px;
  /* added by sohaib */
  --spacing-unit-32: 32px;
  --spacing-unit-40: 40px;
  /* added by sohaib */
}
