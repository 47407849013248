*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
img,
strong,
ul,
li,
form,
label,
table,
tbody,
tfoot,
thead,
tr,
th,
td,
aside,
footer,
header,
nav,
section,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

aside,
footer,
header,
nav {
    display: block;
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

body,
body * {
    font-family: "Cairo";
}

/******************/
/** Todo @suhbib check those rueles */


* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}

body,
body * {
    font-family: "Cairo";
    outline: none !important;
}

a,
a:hover {
    text-decoration: none;
    color: var(--blueColor);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}

/** Todo @suhbib check those rueles END */

.webviewLoaded{
    color:red;
}
.webview .webviewLoaded{
    visibility: hidden;
}
.webview.isLoaded .webviewLoaded{
    visibility: visible;
}

